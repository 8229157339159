<template>
  <div class="donut-chart">
    <apexchart
      :key="chartKey"
      type="donut"
      :options="chartOptions"
      :series="series"
    ></apexchart>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      series: [60, 40],
      chartKey: 0, // Key to force re-render of the chart
      chartOptions: {
        chart: {
          type: "donut",
          width: "100%",
        },
        labels: [], // Placeholder for dynamic labels
        colors: ["#05FFFA", "#6C747C"],
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                name: {
                  show: true,
                },
                value: {
                  show: true,
                  formatter: (val) => `${val}%`,
                },
                total: {
                  show: true,
                  label: "", // Placeholder for dynamic total label
                  formatter: () => `${this.series.reduce((a, b) => a + b, 0)}%`,
                },
              },
            },
          },
        },
        legend: {
          position: "bottom",
        },
      },
    };
  },
  computed: {
    translatedLabels() {
      return [this.$t("Used"), this.$t("Available")];
    },
  },
  watch: {
    translatedLabels() {
      this.updateChartOptions();
    },
  },
  methods: {
    updateChartOptions() {
      // Update chart labels and total label dynamically
      this.chartOptions.labels = this.translatedLabels;
      this.chartOptions.plotOptions.pie.donut.labels.total.label =
        this.$t("Total");

      // Change the chartKey to force a re-render
      this.chartKey += 1;
    },
  },
  mounted() {
    // Initialize the chart options with translated labels on mount
    this.updateChartOptions();
  },
};
</script>

<style lang="scss">
.donut-chart {
  svg {
    path {
      stroke: none;
    }
  }
  .apexcharts-legend-marker {
    width: 20px !important;
    height: 20px !important;
    margin-right: 10px;
  }
}
.dark-layout {
  .apexcharts-legend-text {
    color: #fff !important;
  }
  .apexcharts-datalabels-group {
    text {
      fill: #fff;
    }
  }
}
</style>
