<template>
  <div class="dashboard-page">
    <div class="mb-2">
      <h1>
        {{ $t("Welcome back") }}, {{ user.first_name }}
        {{ user.last_name }}
      </h1>
      <p>
        {{
          $t(
            "Enhance your dental practice's efficiency with our all-in-one management solution"
          )
        }}.
      </p>
    </div>
    <div class="card">
      <div class="card-body">
        <b-row>
          <b-col lg="8">
            <b-row>
              <b-col cols="3">
                <div class="status-card mb-2 mb-lg-0">
                  <h4>{{ $t("Today") }}</h4>
                  <h3>{{ stats.todayCases }}</h3>
                </div>
              </b-col>
              <b-col cols="3">
                <div class="status-card mb-2 mb-lg-0">
                  <h4>{{ $t("This Week") }}</h4>
                  <h3>{{ stats.thisWeekCases }}</h3>
                </div>
              </b-col>
              <b-col cols="3">
                <div class="status-card mb-2 mb-lg-0">
                  <h4>{{ $t("This Month") }}</h4>
                  <h3>{{ stats.thisMonthCases }}</h3>
                </div>
              </b-col>
              <b-col cols="3">
                <div class="status-card mb-2 mb-lg-0">
                  <h4>{{ $t("Total") }}</h4>
                  <h3>{{ stats.totalCases }}</h3>
                </div>
              </b-col>
            </b-row>
            <div class="chart-header mt-2 mb-1">
              <h3>{{ $t("Statistics") }}</h3>
              <div class="d-flex align-items-center gap-2">
                <p>{{ $t("Last Week") }}</p>
                <div class="calender">
                  <input type="date" v-model="date" placeholder="" />
                </div>
                <div class="action">
                  <ElementIcon v-bind:icon="'barVerticalIcon'" />
                </div>
              </div>
            </div>
            <div class="spiline-chart">
              <Chart :series="stats.series" />
            </div>
            <b-row>
              <b-col cols="3">
                <div class="status-card mb-2 mb-lg-0">
                  <h4>{{ $t("Active") }}</h4>
                  <h3>{{ stats.activeCases }}</h3>
                </div>
              </b-col>
              <b-col cols="3">
                <div class="status-card mb-2 mb-lg-0">
                  <h4>{{ $t("Flagged") }}</h4>
                  <h3>{{ stats.flaggedCases }}</h3>
                </div>
              </b-col>
              <b-col cols="3">
                <div class="status-card mb-2 mb-lg-0">
                  <h4>{{ $t("Finished") }}</h4>
                  <h3>{{ stats.finishedCases }}</h3>
                </div>
              </b-col>
              <b-col cols="3">
                <div class="status-card mb-2 mb-lg-0">
                  <h4>{{ $t("Trash") }}</h4>
                  <h3>{{ stats.trashCases }}</h3>
                </div>
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="4">
            <div class="e-card" style="height: 320px">
              <h4>{{ $t("Storage") }}</h4>
              <div
                class="d-flex align-items-center justify-content-center w-100 h-100"
              >
                <DonutChart />
              </div>
            </div>
            <div v-if="filteredNews" class="e-card mt-2" style="height: 250px">
              <h4>{{ filteredNews.subject }}</h4>
              <div class="events-data">
                <div class="">
                  <h4>{{ filteredNews.createdAt }}</h4>

                  <p v-if="filteredNews.isHighlight == 1">
                    {{ filteredNews.shortDescription }}
                  </p>
                </div>
                <div v-if="filteredNews.thumbnail" class="news-thumbnail ml-1">
                  <img :src="filteredNews.thumbnail.url" alt="" />
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import { BLink, BRow, BCol } from "bootstrap-vue";
import Card from "@/components/elements/Card.vue";
import { mapGetters } from "vuex";
import Chart from "./component/Chart.vue";
import DonutChart from "./component/DonutChart.vue";
import ElementIcon from "@/components/elements/Icon.vue";
export default {
  components: {
    Card,
    BLink,
    BRow,
    BCol,
    Chart,
    ElementIcon,
    DonutChart,
    // Model
  },
  data() {
    return {
      stats: {
        todayCases: 0,
        thisWeekCases: 0,
        thisMonthCases: 0,
        totalCases: 0,
        series: [],
        activeCases: 0,
        flaggedCases: 0,
        finishedCases: 0,
        trashCases: 0,
      },
      date: "",
      news: [],
      localeToLanguageMap: {
        en: "english",
        fr: "french",
        de: "german",
        es: "spanish",
      },
      filteredNews: null,
    };
  },
  watch: {
    date: function (newVal, oldVal) {
      this.getStatistics();
    },
    // Watch for changes in the locale
    "$i18n.locale": function (newVal) {
      this.filterNewsByLocale(newVal);
    },

    // Watch for changes in the news data
    news: function (newNews) {
      this.filterNewsByLocale(this.$i18n.locale);
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  mounted() {
    this.getStatistics();
  },
  methods: {
    filterNewsByLocale(locale) {
      // Map the locale to a language
      const language = this.localeToLanguageMap[locale];

      // Iterate over the object values and find the news matching the language
      const newsEntries = Object.values(this.news); // Get the values of the object

      // Check if newsEntries contains items and filter
      this.filteredNews = newsEntries.find(
        (news) => news.language === language
      );
    },
    async getStatistics() {
      let response = await this.$store.dispatch("caseLists/dashboard", {
        date: this.date,
      });
      this.stats = response?.data;
      this.news = response?.data?.latestNews ?? [];
    },
  },
};
</script>

<style lang="scss">
.news-thumbnail {
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 10px;
  border-radius: 5px;
  img {
    width: 100%;
    height: 100px;
    object-fit: contain;
  }
}
</style>
